import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import SocialLinks from "../../other/SocialLinks/SocialLinks";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// function to convert date "May 29, 2023" to "Mon May 29"
const convertDate = (date) => {
  const d = new Date(date);
  const day = d.toLocaleString("default", { weekday: "short" });
  const month = d.toLocaleString("default", { month: "short" });
  const dayOfMonth = d.getDate();
  return `${day} ${month} ${dayOfMonth}`;
};

const images = [
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

export default function Chat() {
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("male");
  const [instagram, setInstagram] = useState("");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("activeBeachesGroupChat");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [veryInterestedInEvents, setVeryInterestedInEvents] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showFormError, setShowFormError] = useState(false);
  const [agreeAgreements, setAgreeAgreements] = useState(true);
  const [agreementsMustBeChecked, setAgreementsMustBeChecked] = useState(false);
  const [shirtSize, setShirtSize] = useState("M");
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);

  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [event, setEvent] = useState({});

  const { eventId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarValue < 100) {
        setProgressBarValue(progressBarValue + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [progressBarValue]);

  useEffect(() => {
    async function fetchData() {
      // create a mock function that will return a list of events after a delay
      let useMocks = false;
      if (!useMocks) {
        if (eventId) {
          // get events from this api - specific event
          await fetch(`${EVENT_API_URL}?id=${eventId}`)
            .then((res) => res.json())
            .then((data) => {
              console.log("specific event data: ", data);
              if (data && data.socialEvents && data.socialEvents.Item) {
                console.log("setting event: ", data.socialEvents.Item);
                setEvent(data.socialEvents.Item);
              } else {
              }
            })
            .catch((err) => {
              console.log("err", err);
              return [];
            });
        }
        // get events from this api - all public events
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // sort events by date
              data.socialEvents.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
              setEvents(data.socialEvents);

              // set current events to only include events that have not passed
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const currentEvents = data.socialEvents.filter((eventItem) => {
                // if date is before yesterday, don't show it
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return false;
                }
                return true;
              });
              setCurrentEvents(currentEvents);
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    fetchData();
  }, []);

  const getInterestedEventTypes = () => {
    const interestedEventTypes = [
      "volleyball",
      "pickleball",
      "basketball",
      "dance",
      "yoga",
      "hiking",
      "biking",
      "skating",
      "running",
      "kayaking",
      "frisbee",
      "learning new languages",
      "learning new skills",
      "game nights",
      "beach hangouts",
      "paddle boarding",
    ];
    // format the types into an array of checkboxes like in upcomingEvents()
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap", // Allows items to wrap into the next line
          justifyContent: "space-between", // Adjust this to change how items are spaced
        }}
      >
        {interestedEventTypes.map((type, i) => {
          return (
            <div
              key={i}
              className="event-container"
              style={{
                width: "48%", // Adjust this to change how many items are in a row
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #e6e6e6",
                backgroundColor: "#f9f9f9",
                borderRadius: "15px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "10px",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id={`eventCheckbox_${i}`}
                  className="event-checkbox"
                  style={{
                    marginRight: "10px",
                    transform: "scale(2.0)", // Increase checkbox size
                  }}
                  // onchange will just be a function that adds the type to the veryInterestedInEvents state
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVeryInterestedInEvents(
                        veryInterestedInEvents + ", " + type
                      );
                    } else {
                      setVeryInterestedInEvents(
                        veryInterestedInEvents.replace(", " + type, "")
                      );
                    }
                  }}
                />
              </div>
              <div className="event-details">
                <small
                  className="font-weight-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {type}
                </small>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const upcomingEvents = () => {
    //
    return (
      <>
        <div style={{ paddingBottom: "20px" }}>
          {currentEvents.length > 0 ? (
            <>
              {/* display each element in events array in a compact bootstrap card */}
              {currentEvents.slice(0, 8).map((eventItem, i) => {
                // if date is before yesterday, don't show it
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return null;
                }

                // Remove non-alphanumeric characters except spaces, apostrophes, dashes, dollar signs, parenthesis, and plus signs
                const cleanTitle = eventItem.title.replace(
                  /[^a-zA-Z0-9 \-\'\$\(\)\+]/g,
                  ""
                );

                return (
                  <div
                    key={i}
                    className="event-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #e6e6e6",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "15px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        id={`eventCheckbox_${i}`}
                        className="event-checkbox"
                        style={{
                          marginRight: "10px",
                          transform: "scale(2.0)", // Increase checkbox size
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedEvents([
                              ...selectedEvents,
                              // eventItem but remove characters from title prop that are not letters, numbers, or spaces
                              {
                                title: eventItem.title.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                ),
                                date: eventItem.date,
                                time: eventItem.time,
                                socialEventId: eventItem.socialEventId,
                              },
                            ]);
                          } else {
                            setSelectedEvents(
                              selectedEvents.filter(
                                (item) =>
                                  item.socialEventId != eventItem.socialEventId
                              )
                            );
                          }
                          console.log("selectedEvents", selectedEvents);
                        }}
                      />
                    </div>
                    <div className="event-details">
                      <small
                        className="font-weight-bold"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {cleanTitle}
                      </small>
                      <br />
                      <small
                        className="text-muted"
                        style={{ fontSize: "12px" }}
                      >
                        {convertDate(eventItem.date)} @ {eventItem.time}
                      </small>
                      {/* <br />
                      <small
                        className="text-muted"
                        style={{ fontSize: "12px" }}
                      >
                        @ {eventItem.location}
                      </small> */}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {events.length < 1 ? (
                <></>
              ) : (
                <div className="text-center">
                  <p>No upcoming events near Jax Beach</p>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const onFormSubmit = (e) => {
    setFormError("");

    // validate form against yup schema
    const schema = yup.object().shape({
      email: yup.string().max(100).email().required(),
      firstName: yup.string().max(100).required(),
      lastName: yup.string().max(100).required(),
      message: yup.string().max(1000).required(),
      sex: yup.string().max(6).required(),
      phone: yup.number().required(),
      instagram: yup.string().max(100).optional(),
      veryInterestedInEvents: yup.string().max(1000).optional(),
      selectedEvents: yup.array().optional(),
      shirtSize: yup.string().max(6).optional(),
    });

    // if message length is < 2 just add some default text there
    let messageHolder = null;
    if (!message || message.length < 2) {
      console.log("form submitted, changing message to default");
      messageHolder = "default";
    } else {
      messageHolder = message;
    }

    schema
      .validate({
        email,
        firstName,
        lastName,
        sex,
        message: messageHolder,
        phone,
        instagram,
        veryInterestedInEvents,
        selectedEvents,
        shirtSize,
      })
      .then(() => {
        setFormEnabled(false);
        setProgressBarValue(0);

        // backend Joi request schema
        // const schema = Joi.object({
        //   formType: Joi.string().valid("formSocialGroupChat").max(100).required(),
        //   firstName: Joi.string().max(100).required(),
        //   lastName: Joi.string().max(100).required(),
        //   email: Joi.string().max(100).email().required(),
        //   message: Joi.string().max(1000).required(),
        //   phone: Joi.string().max(50).required(),
        //   photo: Joi.string().max(100).required(),
        //   referredBy: Joi.string().max(100).required(),
        //   sex: Joi.string().max(50).required(),
        //   socials: Joi.string().max(200).required(),
        // });

        // trim veryInterestedInEvents of any leading or trailing whitespace and ensure whatever is before the first comma is trimmed and has a length of at least 2 characters
        // let trimmedVeryInterestedInEvents = veryInterestedInEvents
        //   .trim()
        //   .split(",")
        //   .map((item) => item.trim())
        //   .filter((item) => item.trim().length > 1);

        // // if the length of trimmedVeryInterestedInEvents is 0, set it to an empty array
        // if (trimmedVeryInterestedInEvents.length === 0) {
        //   trimmedVeryInterestedInEvents = [];
        // }

        // setVeryInterestedInEvents(trimmedVeryInterestedInEvents);

        // remove comma and spaces if they start the string
        let trimmedVeryInterestedInEvents = veryInterestedInEvents.trim();
        if (trimmedVeryInterestedInEvents.startsWith(",")) {
          trimmedVeryInterestedInEvents =
            trimmedVeryInterestedInEvents.substring(1);
        }

        // build the request
        const req = {
          email,
          firstName,
          lastName,
          message: messageHolder,
          phone,
          photo: "default",
          referredBy: "default",
          sex,
          socials: "instagram: " + instagram,
          veryInterestedInEvents: trimmedVeryInterestedInEvents.trim(),
          selectedEvents,
          formType: "formSocialGroupChat",
          shirtsize: shirtSize,
        };

        // send the request to the server
        // local endpoint
        const formApi = {
          local: "http://localhost:3001/formWaitingListAgentPurchases",
          local2:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
          production:
            "https://jf0dzeinoa.execute-api.us-east-1.amazonaws.com/prod/socialchatform",
        };

        fetch(formApi[env], {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // access-control-allow-origin: *

            "Access-Control-Allow-Origin": "*", // this is the important part
          },
          body: JSON.stringify(req),
        })
          .then((res) => res.json())
          .then(
            (data) => {
              console.log("data", data);
              setFormEnabled(false);
              setProgressBarValue(100);

              // if data.error is set, set the error message to formError
              if (data.error) {
                // TODO: handle error cases
                setFormError(JSON.stringify(data.error));
              } else {
                setFormSuccess(true);
                navigate("/subscribe");
              }
            } // end of .then()
          ); // end of fetch()
      })
      .catch((err) => {
        console.log("err", err);
        setFormError("All fields are required unless specified");
        // setFormError(err.errors[0]);
        setFormEnabled(true);
      });
  };

  const getForm = () => {
    if (formEnabled) {
      return (
        <>
          {/* form with text box for QR code link */}
          <div className="form" style={{ marginTop: "-60px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/activebeaches_logo_cropped_03.png`}
              alt={`image-1`}
              style={{
                maxWidth: "100px", // make image responsive
                height: "auto", // auto adjust the height
              }}
            />
            <h3>Join Group</h3>
            <div
              style={{ marginBottom: "20px" }} // 20px space between images
            >
              <img
                src={`https://s3.amazonaws.com/social-images-public/main/7.jpg`}
                alt={`image-7`}
                style={{
                  maxWidth: "100%", // make image responsive
                  height: "auto", // auto adjust the height
                  borderRadius: "15px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #e6e6e6",
                }}
              />
            </div>
            <form>
              {/* first name */}
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              {/* last name */}
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              {/* phone number */}
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              {/* email */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* sex - radio buttons: "male" and "female" */}
              <div className="form-group" style={{ textAlign: "center" }}>
                <label>Sex</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    transform: "scale(1.2)",
                  }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <input
                      type="radio"
                      id="male"
                      name="sex"
                      value="male"
                      onChange={() => setSex("male")}
                      checked={sex === "male"}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="female"
                      name="sex"
                      value="female"
                      onChange={() => setSex("female")}
                      checked={sex === "female"}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>
                <br />
              </div>

              {/* t shirt size select dropdown - XS, S, M, L, XL, XXL*/}
              <div className="form-group">
                <label htmlFor="shirtSize">T-Shirt Size</label>
                <select
                  className="form-control"
                  id="shirtSize"
                  onChange={(e) => setShirtSize(e.target.value)}
                >
                  <option value="XS">XS</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </div>
              <br />

              {/* message */}
              <div className="form-group">
                <label htmlFor="message">How did you hear about us?</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  placeholder="Enter Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              {/* veryInterestedInEvents */}
              <div className="form-group">
                {events.length > 0 ? (
                  <>
                    <label htmlFor="veryInterestedInEvents">
                      What upcoming events are you interested in? (optional)
                    </label>
                  </>
                ) : null}
                {/* show upcoming events */}
                {upcomingEvents()}
                {/* interested event types label */}
                <label
                  style={{
                    lineHeight: "1",
                    paddingTop: "10px",
                    paddingBottom: "15px",
                  }}
                >
                  What do you like to do? <br />
                  (optional)
                </label>
                {/* show interested event types */}
                {getInterestedEventTypes()}
                <label
                  style={{
                    lineHeight: "1",
                    paddingTop: "20px",
                    paddingBottom: "15px",
                  }}
                >
                  Tell us any other events you are interested in here:
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="veryInterestedInEvents"
                  placeholder="volleyball, pickleball, etc."
                  onChange={(e) => setVeryInterestedInEvents(e.target.value)}
                />
              </div>
              <br />

              {/* Error Message Display Div */}
              {formError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                >
                  <strong>Error!</strong> {formError}
                </div>
              ) : null}
              <div
                style={{
                  fontSize: "12px",
                  justifyContent: "center",
                  margin: "10px",
                  alignItems: "center",
                }}
              >
                By submitting this form, I agree to abide by the Active Beaches
                terms of service found at{" "}
                <a href="/tos">activebeaches.com/tos</a>&nbsp;receive emails and
                texts from Active Beaches, to have photos taken at events and be
                posted on social media and other marketing materials, and to
                hold harmless Active Beaches and its partners and organizers for
                any injuries or damages that may occur at events.
              </div>

              {/* submit button */}
              <button
                type="submit"
                className="btn btn-lg btn-block btn-primary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  onFormSubmit(e);
                }}
              >
                I agree, submit
              </button>
            </form>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="outer-page-container">
      <div className="page">
        <div
          style={{
            padding: "10px",
            borderRadius: "5px",
            marginLeft: "auto",
            marginTop: "20px",
          }}
        >
          {/* Error Message Display Div */}
          {formError ? (
            <div
              id="error-message"
              className="alert alert-danger"
              role="alert"
              style={{ marginBottom: "60px" }}
            >
              <strong>Error!</strong>
              <br />
              <p>
                There was a problem submitting the form. Please try again and be
                sure to fill out every field in the form.
              </p>
              {/* button to navigate to reload again */}
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                Try Again
              </button>
              <br />
              <br />
              {/* button to show more details */}
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFormError(!showFormError);
                }}
              >
                Show Error Details
              </button>
              {/* Error Message Display Div in 12px font */}
              {showFormError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                  style={{ fontSize: "12px" }}
                >
                  {formError}
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Success Message Display Div */}
          {formSuccess ? (
            <div
              id="success-message"
              className="alert alert-success"
              role="alert"
            >
              <strong>Success!</strong> We got your request to join our group!
              <p>
                The cost to join the group is $
                {process.env.REACT_APP_MEMBERSHIPCOST_USD} per month.
              </p>
              <p>
                Click the button below to be redirected to the subscribe page.
              </p>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/subscribe");
                }}
              >
                Go to Payment Page
              </button>
            </div>
          ) : null}

          {/* Loading Message Display Div */}
          {!formEnabled && !formError && progressBarValue != 100 ? (
            <>
              <div
                id="loading-message"
                className="alert alert-info"
                role="alert"
              >
                <strong>
                  If you are not shown a success message within 20 seconds,
                  please refresh the page and try again.
                </strong>
              </div>
              {/* create a loading spinner with inline css */}
              <ProgressBar now={progressBarValue} animated />
            </>
          ) : null}
        </div>

        {getForm()}

        <br />
        <div style={{ padding: "10px" }}>
          <br />
          <br />
          {SocialLinks()}
        </div>

        {/* footer */}
        {/* <div className="footer">
          <p>&copy; {new Date().getFullYear()} Beach Social Group</p>
          <p>
            <a
              href="http://davidhudman.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed by David Hudman
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
}
