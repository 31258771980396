import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import SocialLinks from "../../other/SocialLinks/SocialLinks";

import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "main/1.jpg",
  "main/2.jpg",
  "main/3.jpg",
  "main/4.jpg",
  "main/5.jpg",
  "main/6.jpg",
  "main/7.jpg",
  "main/8.jpg",
  "main/9.jpg",
  "main/10.jpg",
  "main/11.jpg",
  "main/12.jpg",
  "main/13.jpg",
];

// [
//   "sip_stroll_0001.jpg",
//   "skate_0001.jpg",
//   "top_golf_0001.jpg",

//   "beach_volleyball_0002.jpg",
//   "frisbee_0003.jpg",
//   "silent_disco_ladies_0001.jpg",
// ];

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

const Home = () => {
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [event, setEvent] = useState({});

  const { eventId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      // create a mock function that will return a list of events after a delay
      let useMocks = false;
      if (!useMocks) {
        if (eventId) {
          // get events from this api - specific event
          await fetch(`${EVENT_API_URL}?id=${eventId}`)
            .then((res) => res.json())
            .then((data) => {
              console.log("specific event data: ", data);
              if (data && data.socialEvents && data.socialEvents.Item) {
                console.log("setting event: ", data.socialEvents.Item);
                setEvent(data.socialEvents.Item);
              } else {
              }
            })
            .catch((err) => {
              console.log("err", err);
              return [];
            });
        }
        // get events from this api - all public events
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // sort events by date
              data.socialEvents.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
              setEvents(data.socialEvents);

              // set current events to only include events that have not passed
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const currentEvents = data.socialEvents.filter((eventItem) => {
                // if date is before yesterday, don't show it
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return false;
                }
                return true;
              });
              setCurrentEvents(currentEvents);
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    fetchData();
  }, []);

  // function to convert date "May 29, 2023" to "Mon May 29"
  const convertDate = (date) => {
    const d = new Date(date);
    const day = d.toLocaleString("default", { weekday: "short" });
    const month = d.toLocaleString("default", { month: "short" });
    const dayOfMonth = d.getDate();
    return `${day} ${month} ${dayOfMonth}`;
  };

  const membershipCard = () => (
    <a
      href="/memberships"
      rel="noopener noreferrer"
      className="btn btn-lg btn-block btn-success custom-btn"
    >
      <div>Join as Member</div>
      <div style={{ fontSize: ".6em" }}>
        men ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo
      </div>
      <div
        style={{
          fontSize: ".6em",
          fontStyle: "italic",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        women FREE
      </div>
    </a>
  );

  const upcomingEvents = () => (
    <>
      <div className="home" style={{ paddingBottom: "10px" }}>
        {currentEvents.length > 0 ? (
          <>
            <span style={{ paddingRight: "0px" }}>
              {/* display each element in events array in a compact bootstrap card */}
              {currentEvents.slice(0, 3).map((eventItem, i) => {
                // if date is before yesterday, don't show it
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return null;
                }
                return (
                  <button
                    onClick={() =>
                      navigate(`/events/${eventItem.socialEventId}`)
                    }
                    key={i}
                    className="text-decoration-none text-dark custom-button"
                  >
                    <div className="button-content">
                      <small className="font-weight-bold">
                        {eventItem.title}
                      </small>
                      <br />
                      <small className="text-muted">
                        {convertDate(eventItem.date)} @ {eventItem.time}
                      </small>
                      <br />
                      <small className="text-muted">
                        @ {eventItem.location}
                      </small>
                    </div>
                    <div className="button-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="purple"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          background: "#FFD166",
                          borderRadius: "50%",
                          padding: "0px",
                        }}
                      >
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                    </div>
                  </button>
                );
              })}
            </span>
          </>
        ) : (
          <>
            {events.length < 1 ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </div>
            ) : (
              <div className="text-center">
                <p>No upcoming events near Jax Beach</p>
              </div>
            )}
          </>
        )}

        <button
          onClick={() => navigate("/events")}
          className="btn btn-block"
          style={{
            backgroundColor: "#71368A",
            color: "#FFFFFF",
            fontSize: "24px",
            width: "100%",
            marginTop: "5px",
            height: "48px",
          }}
        >
          Full Event List
        </button>
      </div>
    </>
  );

  return (
    <div className="outer-home-container">
      <div className="home">
        <div className="inner-home-container">
          <br />
          {upcomingEvents()}
          {/* {membershipCard()} */}

          <button
            onClick={() => navigate("/join")}
            className="btn btn-block"
            style={{
              backgroundColor: "#333333",
              color: "#FFFFFF",
              fontSize: "24px",
              height: "48px",
              marginBottom: "10px",
            }}
          >
            Join Group
          </button>

          <div style={{ padding: "0px" }}>
            {images.map((src, index) => (
              <div
                key={index}
                style={{ marginBottom: "20px" }} // 20px space between images
              >
                <img
                  src={`https://s3.amazonaws.com/social-images-public/${src}`}
                  alt={`image-${index}`}
                  style={{
                    maxWidth: "100%", // make image responsive
                    height: "auto", // auto adjust the height
                  }}
                />
              </div>
            ))}
          </div>

          {/* create event page button */}
          <button
            onClick={() => navigate("/create")}
            className="btn btn-block"
            style={{
              backgroundColor: "#71368A",
              color: "#FFFFFF",
              fontSize: "24px",
              height: "48px",
              marginBottom: "10px",
            }}
          >
            Create Event
          </button>

          {/* {membershipCard()} */}

          {/* create two buttons that are side by side on a single line */}
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col" style={{ flex: 1 }}>
              <a
                href="/memberships"
                rel="noopener noreferrer"
                className="btn btn-lg btn-block btn-default"
                style={{ fontSize: "1.3em" }}
              >
                Memberships
              </a>
            </div>
            <div style={{ width: "20px" }}></div>
            <div className="col" style={{ flex: 1 }}>
              <a
                href="/pay"
                rel="noopener noreferrer"
                className="btn btn-lg btn-block btn-primary"
                style={{ fontSize: "1.3em" }}
              >
                Pay
              </a>
            </div>
          </div> */}

          {SocialLinks()}
        </div>

        {/* footer */}
        <div className="footer">
          <p>
            &copy; {new Date().getFullYear()} {process.env.REACT_APP_GROUP_NAME}
          </p>
          <p>
            <a
              href="http://davidhudman.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed by David Hudman
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
