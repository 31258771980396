import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./merch.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Merch", url: "/merch", active: true },
];

const Merch = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);

  const priceText = "Hats $30 / Shirts $15";
  const callToActionText = "Order a Hat or Shirt";

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          {/* button to take user to google form */}
          <h1
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Merch
          </h1>

          <h2>{priceText}</h2>

          {/* bootstrap button to take user to https://forms.gle/GPkF9yzc5yQwK3N9A to order a hat or shirt */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/GPkF9yzc5yQwK3N9A", "_blank")
            }
          >
            {callToActionText}
          </button>

          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/hat_03.png`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/hat_04.png`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/shirt_03.jpg`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/shirt_04.jpg`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <h2>{priceText}</h2>

          {/* bootstrap button to take user to https://forms.gle/GPkF9yzc5yQwK3N9A to order a hat or shirt */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/GPkF9yzc5yQwK3N9A", "_blank")
            }
          >
            {callToActionText}
          </button>

          <hr />

          <br />
          <div className="footer">
            <p>&copy; Active Beaches {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merch;
